//https://accelerometerdemo.netlify.app/
//https://developer.mozilla.org/en-US/docs/Web/API/Touch_events


// window.addEventListener("pageshow", (event) => {
//   if (event.persisted) {
//     alert("The page was cached by the browser");
//   } else {
//     alert("The page was NOT cached by the browser");
//   }
// });

// import DraggableBlock from './draggableBlock';

// document.addEventListener('DOMContentLoaded', () => {
//   const container = document.querySelector('.debug');

//   // Create a toolbar
//   const toolbar = document.createElement('div');
//   toolbar.className = 'toolbar';
//   document.body.insertBefore(toolbar, container);

//   // Add a button to create new blocks
//   const addButton = document.createElement('button');
//   addButton.textContent = 'Add Block';
//   toolbar.appendChild(addButton);
//   addButton.addEventListener('click', () => {
//     new DraggableBlock(container);
//   });

//   addButton.addEventListener('click', () => {
//     new DraggableBlock(container);
//   });

//   const toggleButton = document.createElement('div');
//   toggleButton.className = 'toggle';
//   toggleButton.textContent = 'debug';
//   toolbar.appendChild(toggleButton);

//   toggleButton.addEventListener('click', () => {
//     //new DraggableBlock(container);
//     toolbar.classList.toggle('show');
//   });

//   // Add a button to duplicate the selected block
//   const duplicateButton = document.createElement('button');
//   duplicateButton.textContent = 'Duplicate Block';
//   toolbar.appendChild(duplicateButton);

//   duplicateButton.addEventListener('click', () => {
//     DraggableBlock.duplicateSelectedBlock(container);
//   });

//   // Add input fields for width and height
//   const widthInput = document.createElement('input');
//   widthInput.type = 'number';
//   widthInput.id = 'widthInput';
//   widthInput.placeholder = 'Width';
//   toolbar.appendChild(widthInput);

//   const heightInput = document.createElement('input');
//   heightInput.type = 'number';
//   heightInput.id = 'heightInput';
//   heightInput.placeholder = 'Height';
//   toolbar.appendChild(heightInput);

//   // Add input fields for left and top position
//   const leftInput = document.createElement('input');
//   leftInput.type = 'number';
//   leftInput.id = 'leftInput';
//   leftInput.placeholder = 'Left';
//   toolbar.appendChild(leftInput);

//   const topInput = document.createElement('input');
//   topInput.type = 'number';
//   topInput.id = 'topInput';
//   topInput.placeholder = 'Top';
//   toolbar.appendChild(topInput);

//   widthInput.addEventListener('input', () => {
//     const width = parseInt(widthInput.value, 10);
//     const height = parseInt(heightInput.value, 10);
//     DraggableBlock.updateBlockSize(container, width, height);
//   });

//   heightInput.addEventListener('input', () => {
//     const width = parseInt(widthInput.value, 10);
//     const height = parseInt(heightInput.value, 10);
//     DraggableBlock.updateBlockSize(container, width, height);
//   });

//   leftInput.addEventListener('input', () => {
//     const left = parseInt(leftInput.value, 10);
//     const top = parseInt(leftInput.value, 10);
//     DraggableBlock.updateBlockPosition(container, left, top);
//   });

//   topInput.addEventListener('input', () => {
//     const left = parseInt(topInput.value, 10);
//     const top = parseInt(topInput.value, 10);
//     DraggableBlock.updateBlockPosition(container, left, top);
//   });

//   // Initialize the first block
//   new DraggableBlock(container);

//   // Add keyboard event listeners for moving the selected block and updating inputs
//   document.addEventListener('keydown', (e) => {
//     const selectedBlock = container.querySelector('.block.selected');
//     const activeInput = document.activeElement;
//     const increment = e.shiftKey ? 10 : 1;

//     if (selectedBlock && !activeInput.matches('input')) {
//       const left = parseInt(selectedBlock.style.left, 10) || 0;
//       const top = parseInt(selectedBlock.style.top, 10) || 0;

//       switch (e.key) {
//         case 'ArrowUp':
//           selectedBlock.style.top = (top - increment) + 'px';
//           break;
//         case 'ArrowDown':
//           selectedBlock.style.top = (top + increment) + 'px';
//           break;
//         case 'ArrowLeft':
//           selectedBlock.style.left = (left - increment) + 'px';
//           break;
//         case 'ArrowRight':
//           selectedBlock.style.left = (left + increment) + 'px';
//           break;
//       }

//       document.getElementById('leftInput').value = parseInt(selectedBlock.style.left, 10);
//       document.getElementById('topInput').value = parseInt(selectedBlock.style.top, 10);
//     } else if (activeInput && activeInput.matches('input')) {
//       switch (e.key) {
//         case 'ArrowUp':
//           activeInput.value = parseInt(activeInput.value, 10) + increment;
//           activeInput.dispatchEvent(new Event('input'));
//           break;
//         case 'ArrowDown':
//           activeInput.value = parseInt(activeInput.value, 10) - increment;
//           activeInput.dispatchEvent(new Event('input'));
//           break;
//       }
//     }
//   });

//   // Deselect blocks when clicking outside any block, but not when clicking input fields
//   document.addEventListener('click', (e) => {
//     if (!e.target.closest('.block') && !e.target.closest('.toolbar')) {
//       const selectedBlock = container.querySelector('.block.selected');
//       if (selectedBlock) {
//         selectedBlock.classList.remove('selected');
//         document.body.style.overflow = ''; // Re-enable scroll
//       }
//     }
//   });
// });






// This will automatically initialize the script as it is part of the script itself






// function getRandomIntInclusive(min, max) {
//   min = Math.ceil(min);
//   max = Math.floor(max);
//   return Math.floor(Math.random() * (max - min + 1) + min); // The maximum is inclusive and the minimum is inclusive
// }
//alert('qwerty!asda  !');

// const Psize = '4.16%';
// var increase = Math.PI / 100;

// import 'mediaelement/build/mediaelementplayer.min.css';
// import { MediaElementPlayer } from 'mediaelement';

// document.addEventListener('DOMContentLoaded', function () {
//   const mediaElement = document.getElementById('player');

//   const player = new MediaElementPlayer(mediaElement, {
//     features: ['playpause', 'progress', 'volume', 'fullscreen'], // Add or remove features as needed
//     success: function (media, node, instance) {
//       // Adding a custom plugin after player initialization
//       instance.buildcustomplugin = function (player, controls, layers, media) {
//         // Add your custom plugin logic here
//         const customButton = document.createElement('div');
//         customButton.className = 'mejs-button mejs-custom-button';
//         customButton.innerHTML = '<button type="button">Custom</button>';

//         controls.appendChild(customButton);

//         customButton.addEventListener('click', function () {
//           alert('Custom plugin button clicked!');
//         });
//       };

//       // Initialize the custom plugin
//       instance.buildcustomplugin(instance, player.controls, player.layers, media);
//     }
//   });
// });


// // Custom plugin example
// MediaElementPlayer.prototype.buildcustomplugin = function (player, controls, layers, media) {
//   // Add your custom plugin logic here
// };

// // Initialize the player with the custom plugin
// document.addEventListener('DOMContentLoaded', function () {
//   const mediaElement = document.getElementById('player');
//   const player = new MediaElementPlayer(mediaElement, {
//     features: ['playpause', 'progress', 'volume', 'fullscreen', 'customplugin'] // Add your custom plugin to the list
//   });
// });



// if you're using a bundler, first import:
// import Headroom from "headroom.js";
// // grab an element
// var myElement = document.querySelector(".hektor-nav-wr");
// // construct an instance of Headroom, passing the element
// var headroom = new Headroom(myElement);
// // initialise
// headroom.init();



//import { DisplayLabel } from './components/DisplayLabel';



// import * as bootstrap from 'bootstrap';
// window.bootstrap = bootstrap;

import Collapse from 'bootstrap/js/dist/collapse';

// Initialize Collapse for each accordion item
document.querySelectorAll('.accordion-collapse').forEach(accordionElement => {
  new Collapse(accordionElement, {
    toggle: false // Ensure it doesn't toggle immediately
  });
});







// import 'dark-mode-switch';
//import '/css/test.scss';

//alert('hoezo kappen hij doet yolo!!!');
//Import our custom CSS 
//import '../../css/src/main.scss';

//import './dark-mode-switch.js';


// import Swiper from 'swiper/bundle';
// import 'swiper/css/bundle';



// let swiper;
// function innitSwiper() {
  
//    swiper = new Swiper('.swiper', {
//     // Optional parameters
//     //direction: 'vertical',
//     loop: false,
//     autoHeight: true,
//     //wrapperClass: 'wp-block-gallery',
//     //slideClass: 'wp-block-image',
//     //containerModifierClass:'wp-block-gallery',
//     // If we need pagination
//     pagination: {
//       el: '.swiper-pagination',
//       clickable: true,
//       dynamicBullets: true,
//       dynamicMainBullets: 3
//     },
//      keyboard: {
//        enabled: true, // Enable keyboard control
//      },
//     // Navigation arrows
//     navigation: {
//       nextEl: '.swiper-button-next',
//       prevEl: '.swiper-button-prev',
//     },

//     // And if we need scrollbar
//     // scrollbar: {
//     //   el: '.swiper-scrollbar',
//     //   draggable: true
//     // },
//     on: {
//       init: function () {
//         // Swiper is ready
        
//         console.log('Swiper is ready');
//         innitBackdrop();
//       }
//     }
//   });


//   // Function to go to a specific slide
  

//   // Example usage:
//   //goToSlide(5); // Go to slide index 2
//   //innitBackdrop();
// }

// document.addEventListener('DOMContentLoaded', function () {
//   innitSwiper();
// });



/* gallery go to index swiper */

// myOffcanvas.addEventListener('hidden.bs.offcanvas', event => {
//   alert('boe');
// })

// barba.init({
//   timeout: 10000,
//   requestError: (trigger, action, url, response) => {
//     // go to a custom 404 page if the user click on a link that return a 404 response status
//     if (action === 'click' && response.status && response.status === 404) {
//       barba.go('/404');
//     }

//     // prevent Barba from redirecting the user to the requested URL
//     // this is equivalent to e.preventDefault() in this context
//     return false;
//   },
//   transitions: [{
    
//     sync: true,
//     name: 'opacity-transition-yolo',
//     leave(data) {
//       return gsap.to(data.current.container, {x: 0, y:0, duration: .25, opacity:0});
//     },
//     enter(data) {
//       return gsap.from(data.next.container, {x: 0, y:0, duration: .4, opacity:0.4});
//     }
//   }]
// });


// barba.hooks.afterEnter((data) => {
//   innitSwiper();
//   //console.log(data.next.namespace);
//   //alert('console.log(data.next.namespace);');
//   var Pnav = document.getElementsByClassName('single-post-nav-wr')[0];
//   var PnavHtml = Pnav.innerHTML;
//   var Cnav = document.getElementsByClassName('outer-barba')[0];
//   Cnav.innerHTML = '';
//   Pnav.innerHTML = '';
//   Pnav.remove();
//   // console.log(Pnav);
//   // console.log('!');
//   Cnav.innerHTML = PnavHtml;
  

 

// });

// var Flickity = require('flickity');
// require('flickity-imagesloaded');
// require('flickity-fullscreen');

//Main.init();


document.addEventListener("DOMContentLoaded", function() {
  
  

  document.querySelectorAll("#wpadminbar a").forEach(item=>item.setAttribute('data-barba-prevent','self'));
  var myOffcanvas = document.getElementById('navbarOffcanvasMd')
  //var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas);

  
 
  
  var windowWidth = window.innerWidth; 
  //console.log(windowWidth);
  if(windowWidth <= 767){
  
    const cbox = document.querySelectorAll(".menu-item");

    for (let i = 0; i < cbox.length; i++) {
      cbox[i].addEventListener("mousedown", function(){
        bsOffcanvas.toggle();
      });
    }

  }

  // var menuitem = document.querySelectorAll('.menu-item');
  // menuitem.addEventListener("mousedown", function(){
  //   alert('!');
  // });
  
  const highlightedItems = document.querySelectorAll(".portfolio-item");
 
  var st = setTimeout(function(){
    highlightedItems.forEach((userItem) => {
      userItem.classList.remove("hide-car");
    });
  },500);
});

console.log(process.env.NODE_ENV);
if (process.env.NODE_ENV !== 'production') {
  console.log('Looks like we are in development mode');
}else{
  console.log('Looks like we are in production mode');
}
 